import { _APIHandler } from "../helpers/webServiceHelper";

export const fetchBannersLists = () => _APIHandler.get(`banners/lists`);
export const listingSuggestions = (queryParams) => _APIHandler.get(`buyers/listings/suggestions/`, { params: queryParams });
export const fetchDynamicListings = (queryParams) => _APIHandler.get(`buyers/listings/dynamic/`, { params: queryParams });
export const fetchListings = (queryParams) => _APIHandler.get(`buyers/listings/`, { params: queryParams });
export const fetchShortlistedListings = (queryParams) => _APIHandler.get(`buyers/listings/shortlisted`, { params: queryParams });
export const fetchListing = (slug) => _APIHandler.get(`listings/slug/${slug}`);
export const contactUsRequest = (data) => _APIHandler.post(`buyers/contact_us`, data);
export const sendListingEnquiry = (data) => _APIHandler.post(`buyers/listing/enquiry`, data);
export const contactSeller = (id) => _APIHandler.get(`buyers/listing/${id}/contact`);
export const doListingShortlist = (data) => _APIHandler.post(`buyers/listing/shortlist`, data);
export const fetchCategoryLists = () => _APIHandler.get(`categories/lists`);
export const fetchSubCategoryLists = (id) => _APIHandler.get(`sub_categories/lists/${id}`);
export const fetchStateWiseDistricts = () => _APIHandler.get(`meta/states/districts`);
export const fetchDistrictsByState = (stateId = 31) => _APIHandler.get(`meta/states/${stateId}/districts/lists`);
export const fetchCityLists = () => _APIHandler.get(`meta/cities/lists`);
export const createListingVisit = (id) => _APIHandler.get(`buyers/listing/${id}/visit`);
export const postYourRequirement = (data) => _APIHandler.post(`listings/requirements`, data);
export const createListingGuestVisit = (id) => _APIHandler.get(`buyers/listing/${id}/visit/guest`);
export const fetchProfile = () => _APIHandler.get(`profile`);
export const updateProfile = (data) => _APIHandler.put(`profile`, data);