import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Form, Button } from "react-bootstrap";
import {
  signIn,
  signUp,
  verifyOTP,
  verifyRegistrationOTP,
} from "../../services/auth";
import farmWayLogo from "../../images/farmway-logo.png";
import signin from "../../images/signin.png";
import OTPInput from "../../components/Inputs/OTPInput";
import { FaTimes } from "react-icons/fa";
import MobileNumberInput from "../Inputs/MobileNumberInput";
import { FaPencil } from "react-icons/fa6";
function SignUp({ OnSetDetaultModal, OnCloseModal }) {
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const formRef = useRef();
  const resendOTP = () => {
    signIn({
      mobile_number: formRef.current.mobile_number.value,
    })
      .then((response) => {
        setIsOTPSent(true);
        setTimeLeft(30);
        toast.success(response.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (isOTPSent === false) {
      await signUp({
        mobile_number: e.target.mobile_number.value,
      })
        .then((response) => {
          setIsOTPSent(true);
          setTimeLeft(30);
          toast.success(response.data.message);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } else {
      await verifyRegistrationOTP({
        first_name: e.target.first_name.value,
        last_name: null,
        mobile_number: e.target.mobile_number.value,
        otp_code: otp,
        portal: "buyer",
        is_buyer: true,
      })
        .then((response) => {
          localStorage.setItem("bam-token", response.data.data.token);
          toast.success(response.data.message);
          OnCloseModal(false);
          window.location.href = "/dashboard";
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);
  return (
    <div className="container-fluid d-flex h-100 align-items-center justify-content-center p-0 m-0">
      <div className="card card-body" style={{ maxWidth: "940px" }}>
        <div className="row mx-0 align-items-center">
          <div className="d-none d-md-block col-md-6 border-end-md p-2 p-sm-5">
            <h2 className="h3">Join</h2>
            <img src={farmWayLogo} alt="FARMWAY" />
          </div>
          <div className="col-md-6">
            <div className="text-right">
              <FaTimes
                onClick={() => OnCloseModal(false)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className=" px-2 pt-2 px-sm-5">
              <h3 className="mt-3">Sign Up</h3>
              <Form ref={formRef} onSubmit={onSubmit}>
                <Form.Group className="mb-4">
                  <Form.Label>Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Name"
                    name="first_name"
                    readOnly={isOTPSent}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>Whatsapp Number *</Form.Label>
                  <MobileNumberInput
                    label="Whatsapp Number"
                    name="mobile_number"
                    isReadonly={isOTPSent ? true : false}
                    isRequired={true}
                  />
                  {isOTPSent ? (
                    <span>
                      <FaPencil
                        onClick={() => setIsOTPSent(false)}
                        style={{ cursor: "pointer" }}
                      />
                    </span>
                  ) : null}
                </Form.Group>
                {isOTPSent ? (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>OTP Code *</Form.Label>
                      <OTPInput length={4} onChangeOTP={(otp) => setOtp(otp)} />
                    </Form.Group>
                    {timeLeft == 0 ? (
                      <>
                        <a
                          href="javascript:void(0);"
                          onClick={() => resendOTP()}
                        >
                          Re-Send OTP
                        </a>
                        <br />
                        <br />
                      </>
                    ) : null}
                  </>
                ) : null}
                {isOTPSent && timeLeft
                  ? "Re-send OTP in " + timeLeft + " Seconds"
                  : null}
                <Button type="submit" size="lg" variant="primary w-100">
                  {isOTPSent ? "Verify OTP" : "Submit"}
                </Button>
              </Form>
              <div className="mt-2 text-center">
                Do have an account?{" "}
                <Button
                  variant="link"
                  className="px-1 py-0"
                  onClick={() => OnSetDetaultModal("login")}
                >
                  Sign In here
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
